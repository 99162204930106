import React from "react";
import Scene from "../components/Scene"
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";

import Layout from "../components/Layout";
import CaseStudyRoll from "../components/CaseStudyRoll";
import Collaborator from "../components/Collaborator";
import ProductList from "../components/ProductList";
import ServiceList from "../components/ServiceList";

export const IndexPageTemplate = ({
  heading,
  headingHighlight,
  description,
  team,
  productlist,
  solutions,
  casestudies,
}) => (
  <div>
    <div className="hero bg-indigo-800 relative overflow-hidden">
      <Scene />
      <div className="max-w-screen-xl md:py-32 mx-auto px-6 py-16 relative">
        <h1 className="font-serif leading-tighter max-w-2xl mb-6 md:text-7xl text-4xl text-white w-2/3">
          {heading} <span className="text-blue-300">{headingHighlight}</span>
        </h1>
        <p className="md:text-lg max-w-sm text-indigo-300 w-2/3">
          {description}
        </p>
        <a className="border-b border-blue-300 focus:outline-none focus:text-blue-300 font-medium hover:text-blue-300 inline-flex items-center md:mt-16 md:text-lg mt-8 pb-2 text-link text-white" href="#products">Learn more 
          <svg className="ml-4 md:ml-6 mt-1" fill="#90CDF4" height=".8em" width=".8em">
            <use xlinkHref="#icon-rightarrow"></use>
          </svg>
        </a>
        <h2 className="mb-3 md:mt-48 md:text-sm mt-24 text-blue-300 text-xs uppercase">{team.heading}</h2>
        <div className="lg:inline-flex">
          <p className="font-serif leading-tight lg:max-w-sm lg:mr-8 mb-6 md:text-5xl text-3xl text-white">
            {team.subheading} <span className="text-blue-300">{team.subheadingHighlight}</span>
          </p>
          <div className="col-break-avoid leading-loose lg:col-count-2 lg:gap-x-16 lg:mt-24 max-w-3xl mb-8 text-indigo-300">
            <p className="mb-6">
              {team.text}
            </p>
            <Link className="border-b border-blue-300 focus:outline-none focus:text-blue-300 font-medium hover:text-blue-300 inline-flex items-center md:mt-16 md:text-lg mt-6 pb-2 text-link text-white" to="/about#team">Meet the team
              <svg className="ml-4 md:ml-6 mt-1" fill="#90CDF4" height=".8em" width=".8em">
                <use xlinkHref="#icon-rightarrow"></use>
              </svg>
            </Link>
          </div>
        </div>
      </div>
    </div>
    <div className="bg-gray-100" id="products">
      <div className="max-w-screen-xl mx-auto px-6 md:py-24 py-12">
        <h2 className="mb-2 md:text-sm text-center text-gray-600 text-xs uppercase">{productlist.title}</h2>
        <p className="font-serif max-w-4xl md:leading-tight md:mb-20 md:text-5xl mx-auto text-2xl text-center text-gray-600 w-11/12">
          {productlist.heading} <span className="text-indigo-800">{productlist.headingHighlight}</span>
        </p>
        <ProductList nestedIn='index-page' />
      </div>
    </div>
    <div className="bg-indigo-800">
      <div className="max-w-screen-xl md:pt-40 md:pb-20 pb-8 pt-20 mx-auto px-6">
        <h2 className="font-serif leading-tight max-w-2xl mb-20 md:mb-32 md:text-5xl md:w-2/3 text-3xl text-white w-11/12">
          {solutions.heading} <span className="text-blue-300">{solutions.headingHighlight}</span>
        </h2>
        <ServiceList nestedIn='index-page' />
      </div>
    </div>
    <div className="bg-gray-100 pb-56">
      <div className="md:pt-24 max-w-screen-xl mx-auto pb-6 pt-4 px-6">
        <h2 className="md:text-sm mb-3 md:mt-12 mt-10 text-gray-600 text-xs uppercase">
          {casestudies.title}
        </h2>
        <div className="flex relative">
          <p className="font-serif leading-tight mb-8 md:mb-10 md:text-5xl text-2xl text-gray-600">
            {casestudies.heading}
            <span className="text-indigo-900">{casestudies.headingHighlight}</span>
          </p>
          <div className="absolute hidden lg:block right-0">
            <Link 
              className="border-2 border-blue-300 focus:bg-blue-300 focus:outline-none font-medium hover:bg-blue-300 py-4 px-6 rounded-full text-indigo-900" 
              to="/case-studies">
                View case studies
             </Link>
          </div>
        </div>
        <div className="mb-8">
          <CaseStudyRoll
              relatedCaseStudy={casestudies.relatedCaseStudy}
              startPos={0}
              limit={3}
          />
        </div>
        <div className="mb-20 md:mt-20 md:mb-10 lg:hidden text-center">
          <Link 
            className="border-2 border-blue-300 focus:bg-blue-300 focus:outline-none font-medium hover:bg-blue-300 py-4 px-8 rounded-full text-indigo-900" 
            to="/case-studies">
            View case studies
          </Link>
        </div>
        <Collaborator />
      </div>
    </div>
  </div>
);

IndexPageTemplate.propTypes = {
  heading: PropTypes.string,
  headingHighlight: PropTypes.string,
  description: PropTypes.string,
  team: PropTypes.object,
  productlist: PropTypes.object,
  solutions: PropTypes.shape({
    title: PropTypes.string,
    heading: PropTypes.string,
    headingHighlight: PropTypes.string,
  }),
  casestudies: PropTypes.shape({
    title: PropTypes.string,
    heading: PropTypes.string,
    headingHighlight: PropTypes.string,
    relatedCaseStudy: PropTypes.shape({
      featuredtitle: PropTypes.array,
    }),
  }),
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <IndexPageTemplate
        heading={frontmatter.heading}
        headingHighlight={frontmatter.headingHighlight}
        description={frontmatter.description}
        team={frontmatter.team}
        productlist={frontmatter.productlist}
        solutions={frontmatter.solutions}
        casestudies={frontmatter.casestudies}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        heading
        headingHighlight
        description
        team {
          heading
          subheading
          subheadingHighlight
          text
        }
        productlist {
          title
          heading
          headingHighlight
        }
        solutions {
          heading
          headingHighlight
        }
        casestudies {
          title
          heading
          headingHighlight
          relatedCaseStudy {
            featuredtitle
          }
        }
      }
    }
  }
`;
