import React from "react";
import { graphql, StaticQuery } from "gatsby";

const Collaborator = class extends React.Component {
  render() {
    const { frontmatter } = this.props.data.markdownRemark;

    return (
      <div className="max-w-screen-xl md:pb-24 md:pt-16 mx-auto pb-12 text-center">
        <h2 className="border-t border-gray-300 font-serif mb-6 md:mb-2 md:pt-20 md:text-5xl pt-12 text-2xl text-indigo-900">{frontmatter.title}</h2>
        <div className="flex flex-wrap items-center lg:justify-between justify-center px-8">
          {frontmatter.collaborators.map((collaborator) => (
            <img
              alt={collaborator.label}
              className="m-4 md:m-6"
              key={collaborator.label}
              src={collaborator.logo.publicURL}
              style={{height: collaborator.height}}
            />
          ))}
        </div>
      </div>
    );
  }
};

export default () => (
  <StaticQuery
    query={graphql`
      query CollaboratorQuery {
        markdownRemark(frontmatter: { key: { eq: "collaborator" } }) {
          frontmatter {
            key
            title
            collaborators {
              label
              logo {
                publicURL
              }
              height
            }
          }
        }
      }
    `}
    render={(data) => <Collaborator data={data} />}
  />
);