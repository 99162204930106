import React from "react";
import * as THREE from "three";
import SceneStyles from "./Scene.module.scss";

class Scene extends React.Component {
  componentDidMount() {
    let scene = new THREE.Scene()
    this.aspect = this.mount.offsetWidth / this.mount.offsetHeight
    this.camera = new THREE.PerspectiveCamera(75, this.aspect, 0.1, 1000)
    this.camera.position.z = 1

    const light = new THREE.AmbientLight(0xe4a5f0, 1)
    const light2 = new THREE.PointLight(0xf4c5fa, 1)
    light2.position.set(-25, 20, 20)
    const light3 = new THREE.PointLight(0xf4c5fa, 0.25)
    light3.position.set(50, -20, -50)

    let geometry = new THREE.OctahedronBufferGeometry(6, 0).scale(1, 1.5, 1)
    let material = new THREE.MeshLambertMaterial({ color: 0x1c1440 })

    const diamond = new THREE.Mesh(geometry, material)
    diamond.position.x = -15
    diamond.position.y = 20
    diamond.position.z = -50
    diamond.rotation.z = -0.2

    const geo = new THREE.WireframeGeometry(diamond.geometry)
    const mat = new THREE.LineBasicMaterial({
      color: 0x7343a7,
    })

    this.wireframe = new THREE.LineSegments(geo, mat)
    diamond.add(this.wireframe)

    geometry = new THREE.OctahedronBufferGeometry(8, 0)
    const diamond2 = new THREE.Mesh(geometry, material)
    diamond2.position.x = 20
    diamond2.position.y = 5
    diamond2.position.z = -60

    geometry = new THREE.IcosahedronBufferGeometry(3, 1)
    const low = new THREE.Mesh(geometry, material)
    low.position.x = 5
    low.position.y = -9
    low.position.z = -18

    const lowgeo = new THREE.WireframeGeometry(low.geometry)
    const lowmat = new THREE.LineBasicMaterial({
      color: 0x7343a7,
    })
    this.wireframe = new THREE.LineSegments(lowgeo, lowmat)
    low.add(this.wireframe)

    geometry = new THREE.IcosahedronBufferGeometry(10, 0)
    material = new THREE.MeshLambertMaterial({ color: 0x7974d3 })
    const main = new THREE.Mesh(geometry, material)
    main.position.z = -35
    main.rotation.z = 0.4

    scene.add(diamond)
    scene.add(diamond2)
    scene.add(main)
    scene.add(low)
    scene.add(light)
    scene.add(light2)
    scene.add(light3)

    this.renderer = new THREE.WebGLRenderer({
      powerPreference: "high-performance",
      antialias: true,
      flatShading: true,
      alpha: true,
    })

    this.mount.appendChild(this.renderer.domElement)

    this.renderer.setPixelRatio(this.mount.devicePixelRatio)
    this.renderer.setSize(this.mount.offsetWidth, this.mount.offsetHeight)
    this.renderer.render(scene, this.camera)
    this.renderer.setAnimationLoop(this.animate)

    this.animate = function () {
      requestAnimationFrame(this.animate.bind(this))

      diamond.rotation.y += 0.0025
      diamond2.rotation.z -= 0.0025
      main.rotation.y -= 0.002
      low.rotation.x -= 0.002

      this.renderer.render(scene, this.camera)
    }

    this.animate()

    window.addEventListener("resize", this.onWindowResize.bind(this), false)
  }
  onWindowResize() {
    if (this.mount) {
      this.camera.aspect = this.mount.offsetWidth / this.mount.offsetHeight
      this.camera.updateProjectionMatrix()
      this.renderer.setSize(this.mount.offsetWidth, this.mount.offsetHeight)
    }
  }

  render() {
    return (
      <div
        className={SceneStyles.wrap + ' absolute'}
        ref={ref => (this.mount = ref)}
      ></div>
    )
  }
}

export default Scene
